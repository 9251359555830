<template>

  <el-dialog v-model="dialogTipPayVisible$" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :title="$t('label.supportMe')" :width="`${w}`">
    <span>{{dialogTipPayTitle$}}</span>
    <div class="d-flex mb-0">
      <div class="d-flex align-items-center">
        <el-icon :size="iconSize" color="#090">
          <Check />
        </el-icon>
      </div>
      <div class="ps-1">{{ $t('label.sendATipToSupportCreators') }}</div>
    </div>
    <div class="d-flex mb-0">
      <div class="d-flex align-items-center">
        <el-icon :size="iconSize" color="#090">
          <Check />
        </el-icon>
      </div>
      <div class="ps-1">{{ $t('label.YouCanSendAMessageToTheCreatorToSupportHim') }}</div>
    </div>
    <div class="d-flex mb-0">
      <div class="d-flex align-items-center">
        <el-icon :size="iconSize" color="#090">
          <Check />
        </el-icon>
      </div>
      <div class="ps-1">{{ $t('label.youCanSeeThisMessageInTheChatHistory') }}</div>
    </div>
    <h4 class="pt-3">{{ $t('label.enterTheSupportedAmount') }}</h4>
    <el-form ref="ruleFormRef" :model="form">
      <el-form-item class="w-100" :rules="[{ required: true, message: $t('messages.required') },{ type: 'number', message: 'Is Number' }]" prop="money">
        <el-input v-model.number="form.money" type="number" :placeholder="$t('label.minimum#$50TWD')"></el-input>
      </el-form-item>
      <h4 class="pt-1">{{ $t('label.theMessageYouWantToSendHer') }}</h4>
      <el-form-item class="w-100" :rules="[{ required: true, message:$t('messages.required') }]" prop="msg">
        <el-input v-model="form.msg" type="textarea" show-word-limit maxlength="200" :placeholder="$t('label.messageContent')"></el-input>
      </el-form-item>
      <h4 class="pt-1">{{ $t('label.chooseThePaymentMethod') }}</h4>
      <el-select class="w-100" placeholder="請選擇付款方式" v-model="form.selectedPayMethod">
        <el-option v-for="item in PayOptions" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
      </el-select>
      <!-- {{ postId }} -->
      <!-- <div style="display:none;">{{ postItem }}</div> -->
      <div class="pay-desc">
        <span class="ps-1">{{ $t('label.paymentDescription') }}</span>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button :loading="onLoading" text @click="store.dispatch('User/dialogTipPayAction', false)">{{ $t('label.cancel') }}</el-button>
        <el-button :loading="onLoading" text type="primary" @click="submit()">
          {{ $t('label.payment') }}
        </el-button>
      </span>
    </template>

  </el-dialog>
</template>

<script>
import { computed, ref, reactive, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { Check } from '@element-plus/icons-vue'
import { useCheckout } from '@/utils/useCheckout'
import { useI18n } from 'vue-i18n'
// import  { FormInstance } from 'element-plus'

export default {
  name: 'TipPay',
  components: {
    Check
  },
  props: {
    item: { type: Array, default: () => [] }
  },
  setup(props) {
    const store = useStore()
    const { t } = useI18n({ useScope: 'global' })
    const { PayOptions, orderCheckout, onLoading } = useCheckout()
    const postItem = ref('')
    // const { PayOptions, orderCheckout, onLoading } = useCheckout()
    const dialogTipPayVisible$ = computed(
      () => store.state.User.dialogTipPayVisible
    )
    watch(dialogTipPayVisible$, newValue => {
      if (newValue) {
        postItem.value = computed(() => store.state.User.dialogPostItem)
      }
    })
    const ruleFormRef = ref(null)
    console.log(props)
    const form = reactive({
      selectedPayMethod: 'ewallet',
      msg: '',
      id: '',
      type: 3,
      money: ''
    })
    const selectedPayMethod = ref('ewallet') // 付款方式
    const iconSize = ref(17)
    const { proxy } = getCurrentInstance()
    const $toast = proxy.$toast
    const submit = async () => {
      ruleFormRef.value.validate(valid => {
        console.log(valid)

        if (!valid) return

        if (form.money < 50) {
          $toast.show(t('label.minimum#$50TWD'))
          return;
        }
        //... 登入～～～.
        orderCheckout(
          form.selectedPayMethod,
          postItem.value.value.post_id,
          postItem.value.value.post_target === '' ? 3 : 8,
          form.number,
          form.money,
          form.msg
        )
      })

      //   .validate((valid) => {
      //   console.log(valid, fields)
      //   debugger
      //   if (valid) {
      //     orderCheckout(
      //       form.selectedPayMethod,
      //       form.id,
      //       form.type,
      //       form.number,
      //       form.money
      //     )
      //     console.log('submit!')
      //   } else {
      //     debugger
      //     console.log('error submit!', fields)
      //   }
      // })
    }
    const w = computed(() => {
      if (window.innerWidth > 1000 && window.innerWidth < 1200) {
        return '50%'
      } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
        return '40%'
      } else {
        return window.innerWidth - window.innerWidth / 10 + 'px'
      }
    })
    const selectedPay = ref('1')

    return {
      dialogTipPayVisible$,
      w,
      iconSize,
      store,
      selectedPay,
      selectedPayMethod,
      PayOptions,
      orderCheckout,
      submit,
      form,
      ruleFormRef,
      postItem,
      onLoading
    }
  }
}
</script>
<style scoped>
.pay-desc {
  font-size: 10px;
}
</style>